import { fetchTopics } from '@api/helpers';
import {
  CarouselImageItemType,
  PageBackgroundImageType,
  StationItemType,
  TopicItemType,
  TopicRegularItemType,
} from '@api/types/types';
import { Button } from '@components/Button/Button';
import { HomeCard } from '@components/Card/HomeCard';
import { Carousel } from '@components/Carousel/Carousel';
import { Headline } from '@components/Headline/Headline';
import { Hero } from '@components/Hero/Hero';
import { LazyLiveHeader } from '@components/LiveHeader/LazyLiveHeader';
import { Masonry } from '@components/Masonry/Masonry';
import { Column, Row } from '@components/Row/Row';
import { Section } from '@components/Section/Section';
import { TopicTagList } from '@components/Tag/TopicTagList';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import { useSelector, wrapper } from '@store/index';
import { clamp } from '@utils/clamp';
import { fetchSharedData } from '@utils/fetchSharedData';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useState } from 'react';

type Props = {
  highlightedTopic: TopicItemType | null;
  stations: StationItemType[];
  pinnedTopics: TopicItemType[];
  carouselImages: CarouselImageItemType[];
  liveStreamHeaderUrl?: string;
  liveStreamHeaderTitle?: string;
  liveStreamHeaderVisible: boolean;
  backgroundImage: PageBackgroundImageType | null;
};

const CurrentProgram = dynamic(
  () => import('../components/CurrentProgram/CurrentProgram'),
  {
    ssr: false,
  }
);

export default function Home({
  stations,
  pinnedTopics,
  carouselImages,
  highlightedTopic,
  liveStreamHeaderUrl,
  liveStreamHeaderTitle,
  liveStreamHeaderVisible,
}: Props) {
  const backgroundImage = useSelector(
    (state) => state.settings.backgroundImage?.data
  );

  return (
    <>
      <NextSeo />

      <Section theme="primary" backgroundImage={backgroundImage}>
        <Row>
          <Column hiddenOnSmallScreen>
            <CurrentProgram />
          </Column>
          <Column>
            {liveStreamHeaderVisible &&
            liveStreamHeaderUrl &&
            /http/.test(liveStreamHeaderUrl) ? (
              <LazyLiveHeader
                streamUrl={liveStreamHeaderUrl}
                title={liveStreamHeaderTitle}
              />
            ) : (
              <Carousel images={carouselImages} />
            )}
          </Column>
        </Row>

        <Section padding="large">
          {pinnedTopics.length > 0 && (
            <PaginatedMasonry topics={pinnedTopics} />
          )}
        </Section>
      </Section>

      {highlightedTopic && highlightedTopic.type !== 'quote' && (
        <HighlightedTopic topic={highlightedTopic} />
      )}
    </>
  );
}

function PaginatedMasonry({ topics }: { topics: TopicItemType[] }) {
  const limit = 6;
  const total = topics.length;
  const lastIndex = Math.ceil(total / limit) - 1;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [items, setItems] = useState(topics.slice(0, limit));

  useDidUpdateEffect(() => {
    setItems(topics.slice(0, (currentIndex + 1) * limit));
  }, [currentIndex, topics, limit]);

  return (
    <>
      <Masonry
        id="pinnedTopics"
        items={items}
        itemKey={(item) => item?.id}
        Component={HomeCard}
      />

      {currentIndex !== lastIndex && (
        <Button
          align="center"
          onClick={() => {
            setCurrentIndex(clamp(currentIndex + 1, 0, lastIndex));
          }}
        >
          Meer laden
        </Button>
      )}
    </>
  );
}

function HighlightedTopic({ topic }: { topic: TopicRegularItemType }) {
  return (
    <Hero image={topic.image?.data} href={`/topics/${topic.slug}`} fullWidth>
      <TopicTagList topic={topic} />
      <Headline theme="pink" text={topic.title} />
    </Hero>
  );
}

export const getStaticProps = wrapper.getStaticProps<Props>(
  ({ dispatch }) =>
    async () => {
      const [{ settings }, pinnedTopics] = await Promise.all([
        await fetchSharedData(dispatch),
        fetchTopics({ tags: ['topic', 'quote'], limit: 18 }),
      ]);

      if (!settings) {
        return {
          notFound: true,
          revalidate: 1,
        };
      }

      const {
        stations,
        editorPick,
        live_stream_header_url,
        live_stream_header_title,
        live_stream_header_visible,
        backgroundImage,
      } = settings;
      const { homepageCarousel, highlightedTopic } = editorPick?.data || {};

      return {
        props: {
          highlightedTopic: highlightedTopic?.data || null,
          pinnedTopics: pinnedTopics.topics,
          stations: stations?.data || [],
          carouselImages: homepageCarousel?.data || [],
          liveStreamHeaderUrl: live_stream_header_url,
          liveStreamHeaderTitle: live_stream_header_title,
          liveStreamHeaderVisible: live_stream_header_visible,
          backgroundImage: backgroundImage?.data || null,
        },
        revalidate: 30,
      };
    }
);

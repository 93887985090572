import {
  ImageItemType,
  ProviderVideo,
  TopicItemImageAspectRatio,
} from '@api/types/types';
import { useInView } from '@hooks/useInView';
import { cx } from '@utils/cx';
import { imageUrl } from '@utils/imageUrl';
import { isHex } from '@utils/isHex';
import 'plyr/dist/plyr.css';
import React, { useEffect, useRef, useState } from 'react';
import styles from './Card.module.css';

export function CardVideo({
  image,
  video,
  ratio = '16:9',
  withGradient = false,
}: {
  image: ImageItemType;
  video: ProviderVideo;
  ratio: TopicItemImageAspectRatio;
  withGradient?: boolean;
}) {
  const { provider, id } = video;
  const { crop, dominant_color } = image;
  const width = 560;
  const [ready, setReady] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const poster = imageUrl(crop).setWidth(width).setRatio('16:9').toString();
  const ref = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { hasIntersected } = useInView({
    ref,
    threshold: 0,
    rootMargin: 100,
  });

  useEffect(
    () => {
      let player: import('plyr') | undefined;
      let mounted = true;
      let playing = false;

      // Use a short timeout before initialising Plyr.
      // Unfortunately the Masonry component will sometimes
      // remount the cards which can cause a race condition with
      // Plyr calling some internal methods (that use setTimout(fn, 0))
      // right after instantiation. If we immediately destroy it again
      // it will sometimes try to run those callbacks on null values.
      window.setTimeout(() => {
        import('plyr').then(({ default: Plyr }) => {
          if (!videoRef.current || !mounted || !hasIntersected) {
            return;
          }

          player = new Plyr(videoRef.current, {
            volume: 0,
            autoplay: false,
            muted: true,
            [provider]: {
              src: id,
              maxHeight: 400,
            },
            controls: [],
          });

          player.source = {
            type: 'video',
            sources: [
              {
                src: id,
                provider,
              },
            ],
          };

          player.on('ready', () => {
            if (player && mounted) {
              window.setTimeout(() => {
                if (mounted) {
                  setReady(true);
                }
              }, 50);
            }
          });
        });
      }, 200);

      function onMouseOver() {
        if (player) {
          player.muted = true;
          const promise = player.play();
          if (promise && promise.then) {
            promise
              .then(() => {
                playing = true;
                setIsOver(true);
              })
              .catch((err) => console.log(err));
          }
        }
      }

      function onMouseOut() {
        if (player && playing) {
          player.pause();
          player.currentTime = 0;
          playing = false;
        }
        setIsOver(false);
      }

      const el = ref.current;

      if (el) {
        el.addEventListener('mouseover', onMouseOver);
        el.addEventListener('mouseout', onMouseOut);
      }

      return () => {
        mounted = false;

        if (el) {
          el.removeEventListener('mouseover', onMouseOver);
          el.removeEventListener('mouseout', onMouseOut);
        }

        if (player) {
          player.stop();
          player.destroy();
        }
      };
    },
    // We only want to set the poster on mount and not destroy
    // the player when the poster image changes between viewports.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider, id, hasIntersected]
  );

  return (
    <div
      ref={ref}
      className={cx(
        styles.CardVideo,
        ready && styles.ready,
        styles[`ratio_${ratio.replace(':', '_')}`],
        withGradient && styles.gradient
      )}
      style={{
        backgroundColor: isHex(dominant_color) ? dominant_color : '#ddd',
      }}
    >
      <video ref={videoRef} muted preload="auto" />

      <div
        className={cx(styles.CardVideoImage, isOver && styles.isOver)}
        style={{ backgroundImage: `url(${poster})` }}
      />
    </div>
  );
}

import { ImageItemType } from '@api/types/types';
import { imageUrl } from '@utils/imageUrl';
import { throttle } from '@utils/throttle';
import { cx } from '@utils/cx';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from './Hero.module.css';

type Props = {
  children: React.ReactNode;
  image?: ImageItemType;
  href?: string;
  fullWidth?: boolean;
  isShop?: boolean;
};

export function Hero({
  children,
  image,
  href,
  fullWidth,
  isShop = false,
}: Props) {
  const [width, setWidth] = useState<800 | 1600 | 2200>(800);
  const ratio = width === 800 ? '2:1' : '3:1';

  useEffect(() => {
    const onResize = throttle(function handleResize() {
      const width = window.innerWidth;
      setWidth(width <= 800 ? 800 : width <= 1400 ? 1600 : 2200);
    }, 333);

    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const crop = image?.crop
    ? imageUrl(image.crop).setRatio(ratio).setWidth(width).toString()
    : undefined;

  const style = crop
    ? {
        backgroundColor: image?.dominant_color,
        backgroundImage: `url(${crop})`,
      }
    : undefined;

  if (href) {
    return (
      <Link href={href}>
        <a className={cx(styles.Hero, fullWidth && styles.fullWidth)}>
          <div className={styles.HeroImage} style={style}>
            <div className={styles.HeroBody}>{children}</div>
          </div>
        </a>
      </Link>
    );
  }

  if (isShop) {
    return (
      <div className={cx(styles.HeroShop, fullWidth && styles.fullWidth)}>
        <div className={styles.HeroImage} style={style}>
          <div className={styles.HeroBody}>{children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.Hero, fullWidth && styles.fullWidth)}>
      <div className={styles.HeroImage} style={style}>
        <div className={styles.HeroBody}>{children}</div>
      </div>
    </div>
  );
}

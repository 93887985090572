import React, { useEffect, useState } from 'react';

export function useInView({
  ref,
  threshold,
  rootMargin,
}: {
  ref: React.MutableRefObject<HTMLElement | null>;
  threshold: number;
  rootMargin: number;
}) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const intersects = entries.some((entry) => entry.isIntersecting);
        setIsIntersecting(intersects);
        intersects && setHasIntersected(true);
      },
      {
        rootMargin: `${rootMargin}px`,
        threshold,
      }
    );

    ref.current && observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return { isIntersecting, hasIntersected };
}

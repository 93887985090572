import { ImageItemType, TopicItemImageAspectRatio } from '@api/types/types';
import { useImageLoaded } from '@hooks/useImageLoaded';
import { useInView } from '@hooks/useInView';
import { cx } from '@utils/cx';
import { imageUrl } from '@utils/imageUrl';
import { isHex } from '@utils/isHex';
import React, { useRef } from 'react';
import styles from './Card.module.css';

export function CardImage({
  image,
  ratio = '16:9',
  withGradient = false,
}: {
  image: ImageItemType;
  ratio: TopicItemImageAspectRatio;
  withGradient?: boolean;
}) {
  const { crop, dominant_color } = image;
  const width = 720;
  const src = imageUrl(crop).setWidth(width).setRatio(ratio).toString();
  const ref = useRef<HTMLDivElement | null>(null);
  const { hasIntersected } = useInView({ ref, threshold: 0, rootMargin: 10 });
  const loaded = useImageLoaded(src, hasIntersected);

  return (
    <div
      ref={ref}
      className={cx(
        styles.CardImage,
        styles[`ratio_${ratio.replace(':', '_')}`],
        withGradient && styles.gradient
      )}
      style={{
        backgroundColor: isHex(dominant_color) ? dominant_color : '#ddd',
      }}
    >
      <span
        style={
          hasIntersected
            ? {
                backgroundImage: `url(${src})`,
                opacity: loaded ? 1 : 0,
              }
            : undefined
        }
      ></span>
    </div>
  );
}

import { TopicItemType } from '@api/types/types';
import { Tag, TagList } from './Tag';

export function TopicTagList({ topic }: { topic: TopicItemType }) {
  if (topic.tags.data.length === 0) {
    return null;
  }

  return (
    <TagList>
      {topic.tags.data.map((tag) => (
        <Tag key={tag.name}>{tag.name}</Tag>
      ))}
    </TagList>
  );
}

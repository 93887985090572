import { SerializableValue } from '@api/types/serializable';
import { useEffect, useRef } from 'react';

export function useDidUpdateEffect(
  effect: () => void,
  deps: (SerializableValue | Date | Function | undefined)[]
) {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      effect();
    } else {
      mounted.current = true;
    }
  }, deps);
}

import { ExternalUrlItemType } from '@api/types/types';

import styles from './Quote.module.css';

type Props = {
  quote: string;
  cite?: string;
};

export function Quote({ quote, cite }: Props) {
  return (
    <figure className={styles.Quote}>
      <blockquote>
        <p>{quote}</p>
      </blockquote>
      {cite && (
        <figcaption>
          <cite>— {cite}</cite>
        </figcaption>
      )}
    </figure>
  );
}

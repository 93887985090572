import { TopicItemType } from '@api/types/types';
import { Card, CardBody } from '@components/Card/Card';
import { CardImage } from '@components/Card/CardImage';
import { CardVideo } from '@components/Card/CardVideo';
import { ExternalUrlList } from '@components/ExternalUrlList/ExternalUrlList';
import { Quote } from '@components/Quote/Quote';
import { TopicTagList } from '@components/Tag/TopicTagList';
import { useIsMobile } from '@hooks/useIsMobile';
import Link from 'next/link';
import { useRouter } from 'next/router';

export function HomeCard({
  data: topic,
}: {
  index: number;
  data: TopicItemType;
  width: number;
}) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const href = `/topics/${topic.slug}`;

  if (topic.type === 'quote') {
    return <Quote quote={topic.title} cite={topic.name} />;
  }

  return (
    <Card
      onClick={() => {
        router.push(href);
      }}
    >
      {topic.video && topic.image && !isMobile ? (
        <CardVideo
          image={topic.image.data}
          video={topic.video}
          ratio={topic.image_aspect_ratio}
          withGradient={topic.show_card_gradient}
        />
      ) : topic.image ? (
        <CardImage
          image={topic.image.data}
          ratio={topic.image_aspect_ratio}
          withGradient={topic.show_card_gradient}
        />
      ) : null}

      <CardBody>
        <TopicTagList topic={topic} />

        <h2>
          <Link href={href}>{topic.title}</Link>
        </h2>
      </CardBody>
      {Array.isArray(topic.external_urls) ? (
        <ExternalUrlList externalUrls={topic.external_urls} />
      ) : null}
    </Card>
  );
}

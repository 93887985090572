import { cx } from '@utils/cx';
import styles from './Headline.module.css';

type Props = {
  text: string;
  theme?: 'blue' | 'pink' | 'red';
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  color?: string;
};

export function Headline({ text, theme = 'blue', level = 2, color }: Props) {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <Tag
      className={cx(styles.Headline, styles[theme])}
      style={
        color
          ? ({
              '--color': color,
            } as React.CSSProperties)
          : undefined
      }
    >
      {text.split(/\s/g).map((word, i) => (
        <span key={`${word}_${i}`}>{word}</span>
      ))}
    </Tag>
  );
}

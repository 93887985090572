import { useIsClient } from '@hooks/useIsClient';
import { useWindowSize } from '@hooks/useWindowSize';
import { Masonry as Masonic } from 'masonic';
import React, { useState, useEffect } from 'react';
import styles from './Masonry.module.css';

type RenderProps<Item> = {
  index: number;
  width: number;
  data: Item;
};

type Props<T> = {
  smallColumnGutter?: number;
  columnGutter?: number;
  minWidth?: number;
  itemHeightEstimate?: number;
  items: T[];
  Component: React.ComponentType<RenderProps<T>>;
  itemKey: (item: T, index: number) => string | number;
  overscanBy?: number;
  id: string;
};

// Wrapper around Masonic to add SSR support.
export function Masonry<T>({
  items,
  Component,
  smallColumnGutter = 24,
  columnGutter = 32,
  minWidth = 320,
  itemHeightEstimate = 425,
  itemKey = (item, index) => index,
  overscanBy = 5,
  id,
}: Props<T>) {
  const { windowWidth } = useWindowSize();

  // Don't use masonry on mobile (single column) devices.
  const [useMasonry, setUseMasonry] = useState(false);
  useEffect(() => {
    setUseMasonry(windowWidth >= 640);
  }, [windowWidth]);

  return useMasonry ? (
    <Masonic
      key={id}
      role="list"
      items={items}
      render={Component}
      columnGutter={windowWidth < 780 ? smallColumnGutter : columnGutter}
      columnWidth={minWidth}
      itemHeightEstimate={itemHeightEstimate}
      itemKey={itemKey}
      tabIndex={-1}
      overscanBy={overscanBy}
    />
  ) : (
    <div className={styles.Masonry}>
      {items.map((data, index) => (
        <Component
          key={itemKey(data, index)}
          index={index}
          data={data}
          width={minWidth}
        />
      ))}
    </div>
  );
}

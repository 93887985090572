import { useEffect, useState } from 'react';

export function useImageLoaded(src: string, start: boolean) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!start || loaded) {
      return;
    }
    const onLoad = () => setLoaded(true);
    const img = new Image();
    img.addEventListener('load', onLoad);
    img.src = src;
    return () => {
      img.removeEventListener('load', onLoad);
    };
  }, [src, setLoaded, start]);

  return loaded;
}

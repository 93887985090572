import { cx } from '@utils/cx';
import { ReactNode } from 'react';
import styles from './Card.module.css';

type Props = { children: ReactNode; onClick?: () => void };

export function Card({ children, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className={cx(styles.Card, onClick != null && styles.clickable)}
    >
      {children}
    </div>
  );
}

export function CardHead({ children }: { children: ReactNode }) {
  return <div className={styles.CardHead}>{children}</div>;
}

export function CardBody({ children }: { children: ReactNode }) {
  return <div className={styles.CardBody}>{children}</div>;
}

import { ExternalUrlItemType } from '@api/types/types';
import styles from '@components/ExternalUrlList/ExternalUrlList.module.css';
import { Link } from '@components/Link/Link';
import { cx } from '@utils/cx';

export function ExternalUrlList({
  externalUrls,
  isQuote = false,
}: {
  externalUrls: ExternalUrlItemType[];
  isQuote?: boolean;
}) {
  return (
    <div className={styles.UrlContainer}>
      {externalUrls.map((data) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          key={data.url}
          href={data.url}
          className={cx(
            isQuote ? styles.QuoteColor : styles.DefaultColor,
            styles.Url
          )}
        >
          {data.title}
        </Link>
      ))}
    </div>
  );
}
